//EXCEL UPLOAD
export const UPLOAD_BIA_EXCEL = "/excel-upload/upload-bia-excel/";
export const UPLOAD_BIA_EXCEL_BY_SUPERUSER =
	"/excel-upload/upload-bia-excel-by-superuser";
export const UPLOAD_RISK_TC_MAPPING_EXCEL =
	"/excel-upload/upload-risk-tc-mapping-excel/";
export const UPLOAD_RISK_ATC_MAPPING_EXCEL =
	"/excel-upload/upload-risk-atc-mapping-excel/";
export const UPLOAD_INDUSTRY_DATA_EXCEL =
	"/excel-upload/upload-industry-data-excel/";
export const UPLOAD_RESPONSE_DISTRIBUTION_EXCEL =
	"/excel-upload/upload-response-distribution-excel/";
export const UPLOAD_COSTS_EXCEL = "/excel-upload/upload-costs-excel";
export const UPLOAD_THREAT_CATALOG_EXCEL =
	"/excel-upload/upload-threat-catalog-excel/";
export const UPLOAD_THREAT_VECTOR_EXCEL =
	"/threat-catalog/upload-threat-vector-excel";
export const UPLOAD_THREAT_CATALOG_THREAT_COUNTS =
	"/excel-upload/upload-threat-analysis-excel";
export const UPLOAD_CONSENSUS_ASSESSMENT_QUESTIONNAIRE =
	"/excel-upload/upload-consensus-assessment-questionnaire/";
export const UPLOAD_NIST = "/excel-upload/upload-nist/";
export const UPLOAD_CONTROLS_CATALOG = "/excel-upload/upload-controls-catalog";
export const BUSINESS_IMPACT_LIST = "/excel-upload/business-impact-list";
export const BUSINESS_IMPACT_BY_PK = "/excel-upload/business-impact/";
export const RISK_TC_LIST = "/excel-upload/risk-tc-list";
export const RISK_TC_BY_PK = "/excel-upload/risk-tc/";
export const RISK_ATC_LIST = "/excel-upload/risk-atc-list/";
export const RISK_ATC_BY_PK = "/excel-upload/risk-atc/:pk/";
export const INDUSTRY_DATA_LIST = "/excel-upload/industry-data-list";
export const INDUSTRY_DATA_CREATE = "/excel-upload/industry-data-create/";
export const INDUSTRY_DATA_BY_PK = "/excel-upload/industry-data/";
export const RESPONSE_DISTRIBUTION_LIST =
	"/excel-upload/response-distribution-list";
export const RESPONSE_DISTRIBUTION_BY_PK =
	"/excel-upload/response-distribution/";
export const COSTS_LIST = "/excel-upload/costs-list-create";
export const COSTS_CREATE = "/excel-upload/costs-list-create";
export const COSTS_BY_PK = "/excel-upload/costs/";
export const THREAT_CATALOG_LIST = "/excel-upload/threat-catalog-list";
export const THREAT_CATALOG_BY_PK = "/excel-upload/threat-catalog/:pk/";
export const CONSENSUS_ASSESSMENT_TITLES =
	"/excel-upload/consensus-assessment-titles";
export const CONSENSUS_ASSESSMENT_QUESTIONNAIRE_LIST =
	"/excel-upload/consensus-assessment-questionnaire-list";
export const CONSENSUS_ASSESSMENT_QUESTIONNAIRE_ADD =
	"/excel-upload/consensus-assessment-questionnaire-add/";
export const CONSENSUS_ASSESSMENT_QUESTIONNAIRE_BY_PK =
	"/excel-upload/consensus-assessment-questionnaire/:pk/";
export const NIST_LISTS = "/excel-upload/nist-lists";
export const NIST_BY_PK = "/excel-upload/nist/";

export const CONTROLS_CATALOG_LIST = "/excel-upload/controls-catalog-list";
export const ADD_CONTROLS_CATALOG = "/excel-upload/add-controls-catalog";
export const CONTROL_CATALOG_DOMAINS = "/excel-upload/control-catalog-domains";
export const CONTROLS_CATALOG = "/excel-upload/controls-catalog";
export const ASSETS = "/excel-upload/assets/";
export const ASSET_LIST = "/excel-upload/asset";
// export const ASSET_BY_PK = "/excel-upload/asset/:int:pk/";
export const CONTROL_EFFECTIVENESS_MATRIXES =
	"/excel-upload/control-effectiveness-matrixes";
export const CONTROL_EFFECTIVENESS_MATRIX_BY_PK =
	"/excel-upload/control-effectiveness-matrix/";
export const CONTROL_TYPE = "/excel-upload/control-type";
export const CONTROL_TYPE_BY_PK = "/excel-upload/control-type/:pk/";
export const CONTROL_APPLICABILITY = "/excel-upload/control-applicability";
export const CONTROL_APPLICABILITY_BY_PK =
	"/excel-upload/control-applicability/:pk/";
export const CONTROL_IMPACT = "/excel-upload/control-impact";
export const CONTROL_IMPACT_BY_PK = "/excel-upload/control-impact/:pk/";
export const CONTROL_SCOPE = "/excel-upload/control-scope";
export const CONTROL_SCOPE_BY_PK = "/excel-upload/control-scope/:pk/";
export const COST_CATEGORY = "/excel-upload/cost-category";
// export const COST_CATEGORY_BY_PK = "/excel-upload/cost-category/:pk/";
export const RISK_AT_MAPPING_LIST = "/excel-upload/risk-at-mapping";
// export const RISK_AT_MAPPING_BY_PK = "/excel-upload/risk-at-mapping/:pk/";
export const RISK_AT_FILE_UPLOAD = "/excel-upload/upload-at-mapping-excel";
