import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { businessProcessData } from "../authentication/store/actions";
import AssetDetails from "../layout/components/Threat Scope/MoreDetails/AssetDetails";
import VendorDetailsBrief from "../layout/components/Threat Scope/MoreDetails/VendorDetailsBrief";
import { baseURL } from "../resources/apiClient";
import http from "../resources/http";
import { formatNumber } from "../utils/formatNumbers";
import LoadingSpinner from "../utils/LoadingSpinner";
import NoDataToShow from "../utils/NoDataToShow";
// import { sessionChecker } from "../utils/sessionChecker";
import Modal from "../shared/Modal";
import { useApi } from "../hooks/useApis";
import apiErrorHandler from "../services/apiErrorHandler";
import ChartComponent from "./Analysis/BusinessImpactAnalysis/ChartComponent";
import PieChartComponent from "./Analysis/BusinessImpactAnalysis/PieChartComponent";
import BusinessAssetCard from "./Analysis/BusinessImpactAnalysis/BusinessAssetCard";
import SmallCard from "./Analysis/BusinessImpactAnalysis/SmallCard";
import { VictoryPie, VictoryTheme, VictoryTooltip } from "victory";

const BusinessImpactAnalysis = (props) => {
	const client_currency = useSelector(
		(state) => state.user?.client_obj?.currency
	);
	const client_currency_symbol =
		client_currency && client_currency === "US DOLLAR"
			? "$"
			: client_currency === "EURO"
			? "€"
			: client_currency === "ETB"
			? "฿"
			: client_currency === "YEN"
			? "¥"
			: client_currency === "WON"
			? "₩"
			: client_currency === "POUND"
			? "£"
			: client_currency === "PESO"
			? "₱"
			: "$";

	// Reusable Asset Card Component
	const AssetCard = ({ title, count, onClick }) => (
		<div className="bg-gray-50 dark:bg-gray-700 rounded-lg shadow-sm p-3 sm:p-4 flex flex-col justify-between transition duration-300">
			<div className="text-center">
				<p className="text-lg sm:text-xl font-bold text-gray-900 dark:text-white">
					{count}
				</p>
				<p className="text-xs sm:text-sm text-gray-500 dark:text-gray-400">
					{/* {title} */}
					{title}
				</p>
			</div>
			<button
				onClick={onClick}
				className=" mt-2 sm:mt-4 bg-gray-100  text-gray-900 py-1 px-2 sm:py-2 sm:px-4 rounded-full text-xs sm:text-sm transition duration-300 hover:bg-gray-200"
			>
				More Details
			</button>
		</div>
	);

	// const processId = useSelector((state) => state.pid);
	const usersCurrency = useSelector((state) => state.user)?.client_obj
		?.currency;
	const processId = useSelector((state) => state.pid);
	const [data, setData] = useState({});
	const [modalAsset, setModalAsset] = useState(false);
	const showModalAsset = () => setModalAsset(!modalAsset);
	const [foo, setFoo] = useState(false);
	const showFoo = () => setFoo(!foo);
	const [fooo, setFooo] = useState(false);
	const showFooo = () => setFooo(!fooo);
	const dispatch = useDispatch();
	const [dat, setDat] = useState([]);
	const [addCompany, setAddCompany] = useState(false);
	const toggleAddCompany = () => setAddCompany(!addCompany);
	const [showAsset, setShowAsset] = useState(false);
	const toggleAsset = () => setShowAsset(!showAsset);
	const [riskDistribution, setRiskDistribution] = useState(false);
	const toggleRiskDistribution = () => setRiskDistribution(!riskDistribution);
	const [businessRisks, setBusinessRisks] = useState(false);
	const toggleBusinessRisks = () => setBusinessRisks(!businessRisks);
	const [loading, setLoading] = useState(true);
	const [fetchLoading, setFetchLoading] = useState(false);
	const [threatCount, setThreatCount] = useState({});

	const processData = useSelector((state) => state.businessProcessData);

	async function fetchDataz() {
		setFetchLoading(true);
		try {
			const response = await http.get(
				`${baseURL}/threat-catalog/threat-counts?businessProcessId=${processId}`
			);
			setThreatCount(response.data);
		} catch (error) {
			apiErrorHandler(error);
		} finally {
			setFetchLoading(false);
		}
	}
	useEffect(() => {
		fetchDataz();
	}, []);

	useEffect(() => {
		http
			.get(`${baseURL}/business_process/business-impact/${processId}`)
			.then((response) => {
				setData(response.data);
				dispatch(businessProcessData(response.data));
				console.log(response.data, "bia");
				setLoading(false);
			})
			.then(
				(response) => {},
				(err) => {
					setLoading(false);
				}
			)
			.catch((err) => {
				return false;
			});
	}, [processId]);

	const totalLowRisk = data?.asset_risks?.find((obj) => obj.total_Low) || 0;
	const totalMediumRisk =
		data?.asset_risks?.find((obj) => obj.total_Medium).total_Medium || 0;
	const totalHighRisk =
		data?.asset_risks?.find((obj) => obj.total_High).total_High || 0;

	const assetDistributionByRiskData = [
		{ x: "High", y: totalHighRisk, fill: "var(--color-vulnerable-high)" },
		{
			x: "Medium",
			y: totalMediumRisk,
			fill: "var(--color-vulnerable-medium)",
		},
		{
			x: "Low",
			y: totalLowRisk,
			fill: "var(--color-vulnerable-low)",
		},
	];

	const threatCountData = [
		{
			x: "Critical",
			y: `${threatCount?.critical}`,
			fill: "var(--color-vulnerable-critical)",
		},
		{
			x: "High",
			y: `${threatCount?.high}`,
			fill: "var(--color-vulnerable-high)",
		},
		{
			x: "Medium",
			y: `${threatCount?.medium}`,
			fill: "var(--color-vulnerable-medium)",
		},
		{ x: "Low", y: `${threatCount?.low}`, fill: "var(--color-vulnerable-low)" },
	];

	return (
		<div>
			{!fetchLoading ? (
				<div className="p-4 sm:p-6 bg-gray-100 dark:bg-gray-900 min-h-screen">
					{/* Cards */}
					<div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 sm:gap-6">
						<SmallCard
							title="Revenue Contribution"
							value={formatNumber(data?.revenue_contribution)}
							clientCurrencySymbol={client_currency_symbol}
							tooltip="Total revenue contribution in UAE Dirham."
						/>
						<SmallCard
							title="Key Business Impact"
							value={data?.key_business_impact}
							tooltip="Potential business impact on costs."
						/>
						<SmallCard
							title="Estimated Business Losses"
							value={data?.estimated_business_loss}
							tooltip="Estimated losses in the business."
						/>
						<SmallCard
							title="Overall Risk Rating"
							value={data?.overall_risk_rating}
							tooltip="The overall risk rating for the business."
						/>
						<SmallCard
							title="Criticality"
							value={data.criticality}
							tooltip="Short-term criticality for the business."
						/>
						<SmallCard
							title="Regulatory Requirements"
							value={
								data?.regulatory_requirements
									? data?.regulatory_requirements?.join(", ")
									: "There is no data at the moment"
							}
							tooltip="Regulatory requirements to comply with."
						/>
					</div>

					{/* Business Assets Section */}
					<div className="mt-6 bg-white dark:bg-gray-800 rounded-lg shadow-lg p-2 sm:p-6 border border-gray-200 dark:border-gray-700 transition-all duration-300 hover:shadow-xl">
						<h3 className="text-lg font-semibold text-gray-700 dark:text-gray-200 mb-4">
							Business Assets
						</h3>
						<div className="grid grid-cols-2 sm:grid-cols-3 gap-2 sm:gap-4">
							{/* <BusinessAssetCard
								title="Assets"
								count={data?.assets}
								onClick={() => toggleAsset()}
							/> */}
							<AssetCard
								title="Assets"
								count={data?.assets}
								onClick={() => toggleAsset()}
							/>
							<AssetCard
								title="Users"
								count={data?.users}
								// onClick={() => openModal("Details about Users")}
							/>
							<AssetCard
								title="Vendors"
								count={data?.vendors}
								onClick={() => toggleAddCompany()}
							/>
						</div>
					</div>

					{/* Business Assets and Risk Charts */}
					<div className="grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-6 mt-6">
						<ChartComponent
							data={assetDistributionByRiskData}
							title="Total Asset Distribution by Risk"
						/>
						<PieChartComponent
							data={threatCountData}
							title="Vulnerability Heat Map"
						/>
					</div>

					<Modal onClose={() => toggleAsset()} size="sm" isOpen={showAsset}>
						<Modal.Header>
							<Modal.Title>Assets</Modal.Title>
						</Modal.Header>
						<Modal.Body>
							<AssetDetails name={dat.name} showHide={toggleAsset} />
						</Modal.Body>
						<Modal.Footer>
							<button className="btn-cancel" onClick={() => toggleAsset()}>
								Close
							</button>
						</Modal.Footer>
					</Modal>

					<Modal
						onClose={() => toggleAddCompany()}
						size="sm"
						isOpen={addCompany}
					>
						<Modal.Header>
							<Modal.Title>Vendors</Modal.Title>
						</Modal.Header>
						<Modal.Body className="">
							<VendorDetailsBrief name={dat.name} showHide={toggleAddCompany} />
						</Modal.Body>
						<Modal.Footer>
							<button className="btn-cancel" onClick={() => toggleAddCompany()}>
								Close
							</button>
						</Modal.Footer>
					</Modal>
				</div>
			) : (
				<LoadingSpinner />
			)}
		</div>
	);
};

export default BusinessImpactAnalysis;
