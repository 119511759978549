const VulnerabilityCard = ({ vuln }) => {
	return (
		<div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-4">
			<h3 className="text-lg font-bold text-gray-800 dark:text-gray-100 mb-2">
				{vuln.id}
			</h3>
			<p className="text-sm font-semibold text-gray-400 mb-2 ">Descriptions</p>

			<p className="text-gray-600 dark:text-gray-400 mb-4">
				{vuln?.descriptions?.[0]?.value}
			</p>
			<div className="flex space-x-4">
				<div className="flex-1">
					<p className="text-sm font-semibold text-gray-400 mb-4">
						Exploitability
					</p>
					<div className="bg-gray-200 dark:bg-gray-600 rounded-full h-6 overflow-hidden">
						<div
							className="bg-red-500 h-6"
							style={{
								width: `${
									(vuln?.metrics?.cvssMetricV2?.[0]?.exploitabilityScore / 10) *
									100
								}%`,
							}}
						></div>
					</div>
				</div>
				<div className="flex-1">
					<p className="text-sm font-semibold text-gray-400 mb-4">Impact</p>
					<div className="bg-gray-200 dark:bg-gray-600 rounded-full h-6 overflow-hidden">
						<div
							className="bg-yellow-500 h-6"
							style={{
								width: `${
									(vuln?.metrics?.cvssMetricV2?.[0]?.impactScore / 10) * 100
								}%`,
							}}
						></div>
					</div>
				</div>
				<div className="flex-1 justify-center">
					<p className="text-sm font-bold text-gray-500 mb-4">Severity</p>
					<h6 className="text font-semibold text-gray-400 ">
						{vuln?.metrics?.cvssMetricV2?.[0]?.baseSeverity}
					</h6>
				</div>
			</div>

			<div className=" flex justify-end items-center mt-2">
				<span className="">Powered by</span>
				<img
					className="h-14 w-20"
					src="https://www.cmswire.com/-/media/d895fbf3203a47808fbf4157e55d5cc8.ashx"
				/>
			</div>
		</div>
	);
};

const VulnerabilityCards = ({ vulnerabilities }) => {
	return (
		<div className="p-6 space-y-6">
			{vulnerabilities.map((vuln, index) => (
				<VulnerabilityCard key={index} vuln={vuln} />
			))}
		</div>
	);
};

export default VulnerabilityCards;
