import React from "react";
import { VictoryPie, VictoryTooltip } from "victory";

const PieChartComponent = ({ data, title }) => {
	// Calculate the total for percentage calculations
	const totalThreatCount = data.reduce((acc, curr) => acc + Number(curr.y), 0);

	if (totalThreatCount === 0) {
		return (
			<div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-4 sm:p-6 border border-gray-200 dark:border-gray-700">
				<h3 className="text-lg font-semibold text-gray-700 dark:text-gray-200 mb-4">
					{title}
				</h3>
				<p className="text-gray-500 dark:text-gray-400">No data available</p>
			</div>
		);
	}

	return (
		<div
			className="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-4 sm:p-6 border border-gray-200 dark:border-gray-700 transition-all duration-300 hover:shadow-xl"
			style={{ height: "300px" }}
		>
			<h3 className="text-lg font-semibold text-gray-700 dark:text-gray-200 mb-4">
				{title}
			</h3>

			<VictoryPie
				data={data}
				animate={{
					duration: 1000,
					easing: "bounce",
				}}
				labelComponent={<VictoryTooltip />}
				labels={({ datum }) =>
					`${datum.x}: ${((datum.y / totalThreatCount) * 100).toFixed(1)}%`
				}
				// innerRadius={80}
				style={{
					data: {
						fill: ({ datum }) => datum.fill,
						fillOpacity: 0.9,
						stroke: "#fff",
						strokeWidth: 2,
					},
					labels: {
						fill: "gray",
						fontSize: 12,
						fontWeight: "bold",
					},
					parent: { maxWidth: "100%" },
				}}
			/>
		</div>
	);
};

export default PieChartComponent;
