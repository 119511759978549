import React, { useEffect, useState } from "react";
import GaugeChart from "react-gauge-chart";
import Chart from "react-google-charts";
import { sensetivities } from "../../data/data";
import { useHandleTheme } from "../../hooks/useHandleTheme";

const RiskScore = ({ data }) => {
	const [sensFilter, setRadioFilter] = useState("High");
	const { darkMode } = useHandleTheme();
	const selectedValue =
		sensFilter === "low"
			? data?.average_risk_score?.severity
			: sensFilter === "Medium"
			? data?.average_risk_score?.severity
			: sensFilter === "High"
			? data?.average_risk_score?.severity
			: "N/A";

	const severityName =
		selectedValue === "veryLow"
			? 20
			: selectedValue === "low"
			? 40
			: selectedValue === "moderate"
			? 60
			: selectedValue === "high"
			? 80
			: selectedValue === "critical"
			? 100
			: 0;

	const series = [severityName];
	const options = {
		width: 400,
		height: 220,
		greenFrom: 0,
		greenTo: 10,
		yellowFrom: 10,
		yellowTo: 20,
		redFrom: 20,
		redTo: 30,
		max: 30,
		animation: {
			duration: 1000,
			easing: "out",
		},
		backgroundColor: "#E4E4",
		pieHole: 1,
		colors: ["black"], // Set the color of the center circle to black
		pieStartAngle: 90,
		// Set this value to remove the gray border circle
		chartArea: {
			backgroundColor: "orange",
		},
	};
	const severityValue = data?.average_risk_score?.severity;
	const dataChart = [
		["Label", "Value"],
		[
			data?.average_risk_score?.severity?.toUpperCase(),
			data?.average_risk_score?.value,
		], // Set the initial indicator value to 30
	];
	return (
		<div className="flex flex-col justify-center items-center">
			<div className="flex flex-col justify-center items-center">
				<GaugeChart
					className="text-green-500"
					id="gauge-chart5"
					nrOfLevels={420}
					arcsLength={[0.2, 0.2, 0.2, 0.2, 0.2]}
					colors={[
						"var(--color-severity-very-low)",
						"var(--color-severity-low)",
						"var(--color-severity-moderate)",
						"var(--color-severity-high)",
						"var(--color-severity-critical)",
					]}
					percent={
						selectedValue === "very_low"
							? 0.1
							: selectedValue === "low"
							? 0.3
							: selectedValue === "moderate"
							? 0.5
							: selectedValue === "high"
							? 0.7
							: selectedValue === "critical"
							? 0.9
							: 0
					}
					hideText={true}
					needleColor="var(--color-cronus-primary)"
					needleBaseColor="var(--color-cronus-primary)"
					arcPadding={0.02}
				/>{" "}
			</div>
		</div>
	);
};

export default RiskScore;
